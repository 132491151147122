<template>
  <div>
    <div class="activityDetail public_main_bgc">
      <div class="w_d center pb20">
        <div class="public-breadcrumb">
          <span class="breadcrumb-before">
            当前位置：
            <router-link to="/">首页</router-link> >
            <router-link to="/researchCenter?navActive=1">教研活动</router-link> >
          </span>
          <span class="breadcrumb-current">活动详情</span>
        </div>

        <div class="activity-frame">
          <div class="activity-frame-head">
            <div class="activity-cover">
              <img :src="detailData.cover_url" />
            </div>
            <div class="activity-info pt20">
              <h2 class="activity-title">{{ detailData.name }}</h2>
              <!-- <p class="activity-synopsis">
              简介
            </p> -->
              <div class="activity-field">
                <div class="activity-field-item">
                  <span class="field-label">活动类型：</span>
                  {{ detailData.type == null ? "" : detailData.type }}
                </div>
                <div class="activity-field-item">
                  <span class="field-label">开展方式：</span>
                  {{ detailData.implement_type }}
                </div>
                <div class="activity-field-item">
                  <span class="field-label">学段学科：</span>
                  {{ detailData.stage_name == null ? "" : detailData.stage_name
                  }}{{ detailData.subject_name == null ? "" : detailData.subject_name }}
                </div>
              </div>
              <p class="activity-date">
                活动时间：{{ detailData.start_date }} {{ detailData.start_time }} 至
                {{ detailData.end_date }} {{ detailData.end_time }}
              </p>
              <div class="activity-block mb20">
                <div class="activity-author">
                  <div class="author-cover">
                    <img :src="detailData.photo_url" />
                  </div>
                  <p class="author-name">{{ detailData.initiator_name || "" }}</p>
                </div>
                <a :href="detailData.group_go_url" target="_blank" class="activity-group">
                  {{ detailData.initiator_type }}</a>
                <!-- <p class="activity-group">{{ detailData.initiator_type }}</p> -->
              </div>
              <div class="activity-block">
                <span class="activity-button-1 mr25" @click="onJoin()" v-if="(detailData.checkUserJoin == 3 || detailData.checkUserJoin == 5 || detailData.checkUserJoin == 7) &&
                detailData.status != 3
                ">
                  我要参与
                </span>
                <span class="activity-button-1 mr25" v-if="detailData.checkUserJoin == 2">
                  报名审核中
                </span>
                <!-- <span class="activity-button-1 mr25" v-if="detailData.checkUserJoin == 6">
                  不允许报名
                </span> -->
                <!-- //参与活动成员待审核列表 -->
                <span class="activity-button-1 mr25" @click="screenWay(2)"
                  v-if="detailData.checkUserJoin == 1 && is_from == 1">
                  待审核报名成员 ({{ checkList.length }})
                </span>
                <new-invitation :access_code="detailData.access_code" class="mr50"></new-invitation>
                <div class="activity-partake" @click="joinTeacher()">
                  <div class="partake-cover">
                    <img :src="item.photo_url" v-for="(item, index) in memberList" :key="index" v-if="index <= 3" />
                  </div>
                  <p class="partake-text">参与教师（{{ detailData.user_count }}）</p>
                </div>
              </div>

              <!-- 投屏 -->
              <!-- <div class="listOne" @click="screenWay(1)" v-if="!(detailData.user_id == user_id && detailData.audit_status != 4)">
                <img src="@/assets/images/Projection_icon.png" alt="" />
              </div> -->
            </div>
            <div class="operate-box" v-if="$store.state.isLogin && isCreator">
              <div>
                <span class="button-style-2" @click="activityPublish()"
                  v-if="detailData.is_user_id == 1 && detailData.setup_status == 1">
                  发布活动
                </span>
                <span class="button-style-2" @click="activityExamine()"
                  v-if="detailData.is_user_id == 2 && detailData.setup_status == 1">
                  提交审核
                </span>
              </div>
              <div>
                <router-link class="button-style-1" :to="editActivityInfoUrl()">
                  <p class="button-text">编辑活动信息</p>
                  <i class="button-icon"></i>
                </router-link>
              </div>
            </div>

            <div class="examine-block"
              v-if="(detailData.audit_status == 1 || detailData.audit_status == 2 || detailData.audit_status == 3) && roleAuth">
              <el-button size="mini" type="primary" @click="examineButton(detailData.id, 4)">审核通过</el-button>
              <el-button size="mini" type="danger" @click="examineButton(detailData.id, 5)">审核不通过</el-button>
            </div>

            <p class="examine-status-block">
              {{ (detailData.is_user_id == 2 && detailData.setup_status != 1) && detailData.audit_status == 2 ? '待审核' :
                detailData.audit_status == 5 ? '未通过' : '' }}
              <el-popover v-if="detailData.audit_status == 5" placement="top-start" title="" width="200" trigger="hover"
                :content="detailData.refusal">
                <i slot="reference" class="el-icon-question"></i>
              </el-popover>
            </p>

          </div>
          <div class="activity-frame-content">
            <div class="activity-frame-header">
              <div class="activity-nav">
                <router-link :to="item.path" v-for="(item, index) in navList" :key="index"
                  :class="navActivePath.indexOf(item.path) != -1 ? 'nav-active' : ''">
                  {{ item.name }}
                </router-link>
              </div>
              <div class="activity-frame-header-right">
                <template v-if="$store.state.isLogin &&
                isCreator &&
                navActivePath.indexOf('basicinfo') != -1
                ">
                  <span class="button-sort" @click="sortButton()" v-if="sortButtonState">
                    <p class="button-text">调整排序</p>
                    <i class="button-icon"></i>
                  </span>
                  <div v-else>
                    <span class="button-sort-cancel mr30" @click="sortCancelButton()">
                      取消
                    </span>
                    <span class="button-sort-confirm" @click="sortConfirmButton()">
                      确认
                    </span>
                  </div>
                </template>
              </div>
            </div>
            <router-view ref="routerView" :key="$route.query.t" :activityInfoData="detailData"></router-view>
          </div>
        </div>
      </div>

      <!-- 投屏/审核报名成员弹窗 -->
      <MyDialog :open="open" :title="title" @onClose="onClose" :width="width">
        <div>
          <ListOne :typeVal="is_who" :activityId="+activityId" :list="checkList" @onCallBack="onWaitCheckList" />
        </div>
      </MyDialog>

      <MyDialog :open="joinTeacherOpen" title="参与教师" @onClose="joinTeacherOpen = false" width="1120px"
        :slot_title="true">
        <div slot="title" class="join-header">
          <p class="join-header-title">参与教师</p>
          <el-button size="mini" class="join-header-download" @click="documentDownload()" v-if="isCreator">
            导出
          </el-button>
        </div>
        <ListOne :typeVal="4" :list="memberList"></ListOne>
      </MyDialog>
    </div>
  </div>
</template>

<script>
import {
  ApplyJoinActivity,
  WaitCheckList,
  StudipApplyJoinActivity,
  SecretLogin,
} from "@/api/teacherStudio";
import ListOne from "@/components/CommonList/ListOne.vue";
import { queryString } from "@/utils/utils.js";
export default {
  components: {
    ListOne,
  },
  data() {
    return {
      // 1教研活动模块，2四名工作室活动模块
      is_who: 1,
      // 1教研活动详情，2四名工作室活动详情
      is_from: 1,
      //活动详情数据
      detailData: {},
      open: false,
      title: "请选择要投屏的电脑或教师",
      width: "38%",
      user_id: localStorage.getItem("id"),
      checkList: [],
      memberForm: {
        is_personid: this.$route.query.is_from ? this.$route.query.is_from : "",
        activity_id: 0,
        limit: 100000,
        page: 1,
      },
      memberList: [],
      joinTeacherOpen: false,
      navList: [
        {
          name: "活动环节",
          path: "/basicinfo", //activityStudyLink
        },
        {
          name: "活动描述",
          path: "/activityStudyDescribe",
        },
        {
          name: "活动成果",
          path: "/activityStudyAchievement",
        },
        {
          name: "活动成员",
          path: "/activityStudyMember",
        },
      ],
      navActivePath: "",
      isCreator: false, //是否创造者
      sortButtonState: true, //排序按钮状态
      roleAuth: false, //角色权限
      //审核表单数据
      examineForm: {
        id: 0,
        audit_status: 0,
        refusal: ''
      },
    };
  },
  created() {
    this.navList.forEach((item) => {
      item.path += `?activityId=${this.$route.query.activityId}&is_from=${this.$route.query.is_from}`;
    });
    this.navActivePath = this.$route.fullPath;
    if (this.$route.query.key) {
      SecretLogin({ key: this.$route.query.key }).then((res) => {
        const dataObj = res.data;
        localStorage.setItem("unserInfoData", JSON.stringify(dataObj));
        //把瓣学网登录的权限存在缓存里
        localStorage.setItem("aes_code", dataObj.aes_code);

        //将用户所有信息存进缓存
        localStorage.setItem("user_name", dataObj.username);

        //把token存在缓存里
        localStorage.setItem("token", dataObj.token);

        //把用户id存进缓存里
        localStorage.setItem("id", dataObj.id);

        this.isShow = 1;
        //把登录显示放进缓存里
        localStorage.setItem("isShow", this.isShow);
        //把用户头像放进缓存
        localStorage.setItem("photo_url", dataObj.photo_url);
        setTimeout(() => {
          this.$router.push({
            path: `/activityStudy?module=${this.$route.query.module}&activityId=${this.$route.query.activityId}&is_from=${this.$route.query.is_from}`,
          });
          location.reload();
        }, 500);
      });
    } else {
      this.init();
    }
  },
  methods: {
    init() {
      this.activityId = this.$route.query.activityId;
      this.is_from = this.$route.query.is_from || 1;
      this.getDetail();
      this.getMember();
      if (this.$store.state.isLogin && this.is_from == 1) {
        // 待审核列表
        this.onWaitCheckList();
      }
    },
    // 请求后台获取活动详情
    getDetail() {
      //教研组活动、个人活动
      if (this.is_from == 1) {
        this.$axios.get(`activity/${this.activityId}`).then((res) => {
          this.detailData = res.data.data;
          // is_who: 1个人，2教研组
          // this.detailData.is_who = 1;
          // permit:1所有人，2自定义
          // this.detailData.permit = 1;
          // sign_up:1开启，2关闭
          // this.detailData.sign_up = 1;
          //this.onGetAchievement();
          this.setActivityIsJoin();
          this.setActivityIsEnable();
        });
      } else {
        this.$axios
          .get(`index/teachingStudioActivity/info`, {
            params: { id: this.activityId },
          })
          .then((res) => {
            this.detailData = res.data.data;
            this.setActivityIsJoin();
            this.setActivityIsEnable();
            this.getTeachingStudioInfo();
          });
      }
    },
    //设置研修活动加入权限
    setActivityIsJoin() {
      let state = "";
      if (this.is_from == 1) {
        state =
          this.detailData.checkUserJoin == 1 || this.detailData.checkUserJoin == 4
            ? 1
            : 0;
      }
      if (this.is_from == 2) {
        state =
          this.detailData.join_permission.hasOwnProperty("state") &&
            this.detailData.join_permission.state == 1
            ? 1
            : 0;
      }
      localStorage.setItem("activity_isJoin", state);
      this.$store.commit("setActivity_isJoin", state);

      if (localStorage.getItem("id") == this.detailData.user_id) {
        this.isCreator = true;
      }
    },
    //设置研修活动是否启用
    setActivityIsEnable() {
      let state =
        this.detailData.audit_status == 4 || this.detailData.setup_status == 2 ? 1 : 0;
      localStorage.setItem("activity_isEnable", state);
      this.$store.commit("setActivity_isEnable", state);
    },
    //活动投屏
    screenWay(type) {
      // 从缓存中得到用户名
      //   let userName = localStorage.getItem("user_name");
      //将用户名使用unicode编码
      //let b= setUnicode(userName);
      //   let b = escape(userName);
      //   window.open("DCYJTP://Type:1_User:" + b);

      if (type === 1) {
        if (!this.detailData.join_TeachingStudio) {
          return this.$message.warning("请先报名参与");
        }
        // 投屏
        this.title = "请选择要投屏的电脑或教师";
        this.is_who = 1;
        this.width = "38%";
      } else {
        // 审核报名成员
        this.title = "审核报名成员";
        this.is_who = 2;
        this.width = "621px";
      }
      this.open = true;
    },
    // 关闭弹窗后的操作
    onClose() {
      this.open = false;
    },
    // 待审核列表
    onWaitCheckList() {
      let obj = {
        activity_id: this.activityId,
        limit: 5000,
        page: 1,
      };
      WaitCheckList(obj).then((res) => {
        console.log(res);
        if (res.data.data.length) {
          this.checkList = JSON.parse(
            JSON.stringify(res.data.data).replace(/realname/g, "username")
          );
        } else {
          this.checkList = [];
        }
      });
    },
    // 我要参与
    onJoin() {
      // state: null未加入工作室,0待审核,1审核通过,2审核不通过
      if (this.detailData.checkUserJoin == 3 || this.detailData.checkUserJoin == 7) {
        // 有参与权限
        let msg = "已发起参与报名，请耐心等候审核结果";
        if (this.detailData.checkUserJoin == 7) {
          msg = "已参与成功，可参与活动环节";
        }
        if (this.is_from == 1) {
          this.onJoinGroupApi(msg);
        } else {
          this.onJoinApi(msg);
        }
      } else {
        // 无参与权限
        this.$refs.joinStudioRef.open = true;
      }
    },
    // 教研组参与接口
    onJoinGroupApi(msg) {
      ApplyJoinActivity({
        activity_id: this.activityId,
        user_id: this.user_id,
      }).then((res) => {
        msg && this.$message.success(msg);
        this.intData();
      });
    },
    onCallBack() {
      //   let msg = "参与成功，请等待审核";
      let msg = "";
      if (this.is_from == 1) {
        this.onJoinGroupApi(msg);
      } else {
        this.onJoinApi(msg);
      }
    },
    // 四名参与接口
    onJoinApi(msg) {
      StudipApplyJoinActivity({
        activity_id: this.activityId,
        user_id: this.user_id,
      }).then((res) => {
        msg && this.$message.success(msg);
        this.intData();
      });
    },
    //初始化数据
    intData() {
      this.init();
      if (this.detailData.checkUserJoin == 7) {
        //刷新当前页面
        location.reload();
      }
    },
    //获取活动成员
    getMember() {
      this.memberForm.activity_id = this.activityId;
      this.$axios
        .get(`index/UserActivity/UserActivityLists`, { params: this.memberForm })
        .then((res) => {
          let data = res.data;
          this.memberList = data.data.data;
          console.log(
            JSON.parse(JSON.stringify(this.memberList)),
            "【 this.memberList 】"
          );
        });
    },
    joinTeacher() {
      this.joinTeacherOpen = true;
    },
    activityPublish() {
      this.$confirm("是否确定发布活动？活动发布成功后将对相关用户开放。", "提示", {
        confirmButtonText: "发布活动",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          this.$axios
            .post(`/activity/${this.activityId}/setType`, { is_personid: this.is_from })
            .then((res) => {
              let data = res.data;
              if (data.code == 900) {
                this.getDetail();
              }
            });
        })
        .catch(() => { });
    },
    activityExamine() {
      this.$confirm(
        "是否确定提交审核？提交后将进入审核流程。审核通过后将对相关用户开放。",
        "提示",
        {
          confirmButtonText: "提交审核",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        }
      )
        .then(() => {
          this.$axios
            .post(`/activity/${this.activityId}/setType`, { is_personid: this.is_from })
            .then((res) => {
              let data = res.data;
              if (data.code == 900) {
                this.getDetail();
              }
            });
        })
        .catch(() => { });
    },
    editActivityInfoUrl() {
      let pathName = "";
      let query = {
        id: this.activityId,
      };
      if (this.is_from == 1) {
        query.groupId = this.detailData.group_id;
        pathName = "activityStudyCreate";
      }
      if (this.is_from == 2) {
        query.studioId = this.detailData.group_id;
        pathName = "CreateActivity";
      }
      return `/${pathName}${queryString(query)}`;
    },
    sortButton(type = "open") {
      this.$refs["routerView"].setDraggable(type).then((state) => {
        this.sortButtonState = !state;
      });
    },
    sortCancelButton() {
      this.sortButton("cancel");
    },
    sortConfirmButton() {
      this.sortButton("confirm");
    },
    documentDownload() {
      this.$axios
        .get("index/UserActivity/export_user_activity_lists", {
          params: {
            activity_id: this.activityId,
            is_personid: this.is_from,
          },
        })
        .then((res) => {
          if (res.data.code == 900) {
            window.open(res.data.data.data.url);
          }
        });
    },
    //获取工作室详情
    getTeachingStudioInfo() {
      this.$axios.get("/index/TeachingStudio/TeachingStudioInfo", {
        params: {
          id: this.detailData.group_id,
          user_id: this.user_id,
        },
      }).then((res) => {
        this.roleAuth = res.data.data.role;
      });
    },
    //审核按钮
    examineButton(id, val) {
      this.examineForm.id = id;
      this.examineForm.audit_status = val;
      if (val == 4) {
        this.myConfirm({
          content: `是否确定通过审核？`,
          fn: this.examine
        });
      }
      if (val == 5) {
        this.$prompt('', '审核不通过', {
          customClass: 'examine-prompt',
          closeOnClickModal: false,
          showClose: false,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputType: 'textarea',
          inputPattern: /^.+$/m,
          inputPlaceholder: '请输入拒绝理由',
          inputErrorMessage: '请输入拒绝理由'
        }).then((e) => {
          if (e.action == "confirm") {
            this.examineForm.refusal = e.value;
            this.examine();
          }
        }).catch(() => {

        });
      }
    },
    //审核
    examine() {
      this.$axios
        .put("TeachingStudioActivity/audit_status", this.examineForm)
        .then(() => {
          this.getDetail();
          this.examineForm.refusal = "";
        });
    },
    //审核不通过（提交）
    examinePopoverSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.examine();
        }
      });
    },
  },
  computed: {},
  filters: {},
  watch: {
    $route: {
      handler(newVal, oldval) {
        this.navActivePath = newVal.fullPath;
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="less">
.public-breadcrumb {
  height: 48px;
  line-height: 48px;

  .breadcrumb-before {
    font-size: 14px;
    color: #666;

    a {
      font-size: 14px;
      color: #666;
    }
  }

  .breadcrumb-current {
    font-size: 14px;
    color: #ff8201;
  }
}

.activity-frame-header {
  height: 70px;
  display: flex;
  border-bottom: 1px solid #e1e5ff;

  .activity-nav {
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;

    a {
      font-size: 20px;
      font-weight: bold;
      color: #666666;
      margin-left: 60px;
    }

    .nav-active {
      color: #3489fe;
    }
  }

  .activity-frame-header-right {
    display: flex;
    align-items: center;
    padding: 0 30px;

    .button-sort {
      height: 40px;
      background: #f0f2ff;
      border-radius: 4px;
      padding: 0 20px;
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      .button-text {
        font-size: 18px;
        color: #333333;
        line-height: 18px;
      }

      .button-icon {
        width: 10px;
        height: 16px;
        background: url("../../../assets/images/icon-sort.png") no-repeat;
        background-size: 100%;
        display: block;
        margin-left: 8px;
      }
    }

    .button-sort-confirm,
    .button-sort-cancel {
      height: 40px;
      background: #3489fe;
      border-radius: 4px;
      padding: 0 20px;
      display: inline-block;
      font-size: 18px;
      color: #ffffff;
      line-height: 40px;
      cursor: pointer;
    }

    .button-sort-cancel {
      background: #ebf3ff;
      color: #3489fe;
    }
  }
}

.show_box_div {
  /deep/.mainBox {
    height: 0 !important;
    display: none !important;
  }
}

.activity-frame {
  .activity-frame-head {
    width: 100%;
    height: 274px;
    padding: 30px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    border-radius: 12px;
    display: flex;
    position: relative;
    margin-bottom: 15px;

    .activity-cover {
      width: 380px;
      height: 214px;
      border-radius: 6px;
      overflow: hidden;
      margin-right: 30px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .activity-info {
      flex: 1;
      overflow: hidden;

      .activity-title {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 10px;
      }

      .activity-synopsis {
        font-size: 16px;
        color: #999999;
        margin-bottom: 4px;
      }

      .activity-field {
        display: flex;

        .activity-field-item {
          font-size: 16px;
          color: #000000;
          margin-right: 80px;

          .field-label {
            color: #999999;
          }
        }
      }

      .activity-date {
        font-size: 16px;
        font-weight: bold;
        color: #ff7e24;
        //padding: 12px 0;
        padding: 10px 0;
      }

      .activity-block {
        display: flex;
        align-items: center;

        .activity-author {
          align-items: center;
          display: flex;
          margin-right: 40px;

          .author-cover {
            width: 24px;
            height: 24px;
            border-radius: 100%;
            overflow: hidden;
            margin-right: 6px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .author-name {
            font-size: 16px;
            color: #000000;
          }
        }

        .activity-group {
          font-size: 16px;
          color: #000000;

          &:hover {
            text-decoration: underline;
          }
        }

        .activity-button-1 {
          width: 169px;
          height: 46px;
          background: #3489fe;
          border-radius: 8px;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          line-height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .activity-button-2 {
          width: 98px;
          height: 46px;
          background: #ffffff;
          border: 1px solid #3489fe;
          border-radius: 8px;
          font-size: 16px;
          color: #3489fe;
          line-height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          cursor: pointer;
        }

        .activity-partake {
          display: flex;
          align-items: center;
          cursor: pointer;

          .partake-cover {
            margin-right: 26px;

            img {
              width: 24px;
              height: 24px;
              border-radius: 100%;
              overflow: hidden;
              margin-right: -12px;
            }
          }

          .partake-text {
            font-size: 16px;
            color: #666666;
            line-height: 16px;
          }
        }
      }

      .listOne {
        width: 0;
        height: 0;
        border-top: 54px solid #3489ff;
        border-left: 54px solid transparent;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;

        >img {
          transform: translate(-26px, -46px);
        }
      }
    }

    .operate-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      .button-style-1 {
        height: 40px;
        background: #ebf3ff;
        border-radius: 40px;
        padding: 0 20px;
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        .button-text {
          font-size: 16px;
          color: #3489fe;
          line-height: 16px;
        }

        .button-icon {
          width: 15px;
          height: 15px;
          background: url("../../../assets/images/icon-edit.png") no-repeat;
          background-size: 100%;
          display: block;
          margin-left: 8px;
        }
      }

      .button-style-2 {
        height: 40px;
        background: #ff9400;
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        line-height: 40px;
        padding: 0 35px;
        display: inline-block;
        cursor: pointer;
      }
    }

    .examine-block {
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1;
      background: #f00;
      padding: 10px;
      background: rgba(234, 241, 247, 1);
      border-radius: 0 8px 0 8px;
    }

    .examine-status-block {
      color: #f05f5f;
      position: absolute;
      right: 20px;
      top: 20px;

      i {
        color: #f05f5f;
      }
    }
  }

  .activity-frame-content {
    background: #fff;
    border-radius: 12px;
  }
}

.public_main_bgc {
  background: #eef2fb;
  min-height: calc(100vh - 180px) !important;
}

.activityDetail {
  /deep/ .is-link {
    color: #606266;
    font-weight: 400;
  }

  .center {
    width: 1400px;
    margin: auto;

    // border: 1px solid black;
    // display: flex;
    // justify-content: space-between;
    .center_left {
      width: 264px;
      background: #ffffff;
      border-radius: 6px;
      padding-bottom: 30px;
      //   height:626px;
      //   display: inline-block;
      float: left;

      .introduce {
        height: 48px;
        display: flex;
        align-items: center;
        padding: 16px 14px;

        .introduceSty {
          width: 100%;
          height: 100%;
          background: rgba(233, 243, 255, 1);
          border-radius: 8px;
          align-items: center;
          padding: 0 16px;
          background-color: #e9f3ff;
          color: #3489fe;
        }

        .introduce_one {
          margin: 30px 0px 24px 30px;
          font-weight: 700;
        }

        .introduce_two {
          cursor: pointer;
          display: flex;
        }

        .active {
          cursor: pointer;
          color: #3489fe;
          display: flex;
        }
      }

      .heng_one {
        width: 100%;
        height: 1px;
        background: #ebebeb;
        margin-bottom: 30px;
      }

      .link_title {
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        padding-left: 28px;
        padding-top: 14px;
        padding-bottom: 20px;
      }

      .link_block {
        // height: 83px;
        padding: 0 27px;
        // line-height: 83px;
        // margin: 0px 15px 0px 30px;
        // border-bottom: 1px dashed #ececec;
        margin-top: 20px;

        .block_one {
          display: flex;
          align-items: center;
          // margin-top: 24px;
          margin-bottom: 12px;

          // i:nth-child(1) {
          //   color: #ffa84f;
          //   margin-right: 14px;
          // }
          .ifin {
            color: #ffa84f;
            margin-right: 14px;
          }

          .inot {
            color: #999999;
            margin-right: 14px;
          }

          p {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 36px;
          }
        }

        //点击样式
        .activeOne {
          display: flex;
          align-items: center;
          // margin-top: 24px;
          margin-bottom: 12px;
          color: #3489fe;

          .ifin {
            color: #ffa84f;
            margin-right: 14px;
          }

          .inot {
            color: #999999;
            margin-right: 14px;
          }

          p {
            width: 203px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 36px;
          }
        }

        .block_two {
          margin-left: 32px;
          width: 51px;
          height: 18px;
          border-radius: 9px;
          margin-bottom: 17px;
          font-size: 12px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .finish {
          background: #cecece;
        }

        .notstart {
          background: #3489fe;
        }

        .going {
          background: #ff8201;
        }
      }

      .link_block:last-child {
        border-bottom: 0px;
      }

      .linkBox {
        width: 100%;
        padding-left: 28px;
        padding-right: 28px;
        box-sizing: border-box;

        .link_item {
          display: flex;
          align-items: center;
          height: 70px;
          cursor: pointer;
        }

        .icon_box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .name_box {
          flex: 1;
          margin-left: 8px;
          font-size: 16px;
          line-height: 20px;
          font-weight: normal;
          color: rgba(51, 51, 51, 1);
          margin-right: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;

          &.active {
            color: #3489fe;
          }
        }

        .name_state_box {
          width: 51px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          border-radius: 4px;
          font-size: 12px;
          font-weight: normal;
        }

        .name_state_1 {
          border: 1px solid rgba(255, 136, 14, 1);
          color: rgba(255, 136, 14, 1);
        }

        .name_state_2 {
          border: 1px solid #cecece;
          color: #cecece;
        }

        .name_state_3 {
          border: 1px solid #3489fe;
          color: #3489fe;
        }
      }
    }

    .center_right {
      width: 1116px;
      float: right;
      // height:500px;
      // border: 1px solid black;
      //   overflow: hidden;
    }
  }

  // 顶部面包屑样式
  .new_bread {
    height: 48px;
    line-height: 48px;

    .bread_content {
      display: flex;
      align-items: center;
      margin-top: 0px;

      .span_one {
        font-size: 14px;
        color: #666666;
      }

      .font_orange {
        color: #ff8910;
      }
    }

    .bread_hengxian {
      width: 100%;
      height: 1px;
      background-color: #f0f0f0;
      margin-top: 32px;
      margin-bottom: 40px;
    }
  }
}

.join-header {
  position: relative;

  .join-header-title {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    line-height: 60px;
    text-align: center;
  }

  .join-header-download {
    position: absolute;
    top: 16px;
    right: 60px;
  }
}
</style>
